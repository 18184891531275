import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class StageSevenService {
  static createStageSeven (data) {
    return axios.post(`${url}/app/stage-seven/create`, data)
  }
}

export default StageSevenService
